import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Cropper from "react-cropper";
import classnames from "classnames";
import "cropperjs/dist/cropper.css";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../CustomButton";

import {
  INITIAL_CROPPER_STATE,
  MAX_IMAGE_WIDTH,
  MAX_IMAGE_HEIGHT,
  MIN_CROP_BOX_WIDTH,
  MIN_CROP_BOX_HEIGHT,
} from "./constants/index";

export const ImageCropper = ({
  selectedImage,
  onCropImage,
  onCloseCropper,
  isRoundedCropper,
}) => {
  const [cropState, setCropState] = useState(INITIAL_CROPPER_STATE);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const cropper = useRef(null);

  const cropImage = useCallback(() => {
    if (!cropper?.current?.cropper?.getCroppedCanvas()) return;

    setSaveDisabled(true);

    const canvas = cropper.current.cropper.getCroppedCanvas({
      maxWidth: MAX_IMAGE_WIDTH,
      maxHeight: MAX_IMAGE_HEIGHT,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: "high",
    });
    const timestamp = new Date().getTime();
    const originalName = selectedImage?.path || "cropped-image.png";
    const fileExtension = originalName?.split(".")?.reverse()?.[0] || "png";
    const fileName = `${originalName.replace(
      `.${fileExtension}`,
      ""
    )}_${timestamp}.${fileExtension}`;

    canvas.toBlob(
      (blob) => {
        if (!blob) {
          setSaveDisabled(false);
          Bugsnag.notify(new Error("Failed to create blob"), (event) => {
            event.addMetadata("selectedImage", selectedImage);
          });

          return;
        }

        const blobUrl = URL.createObjectURL(blob);
        const file = new File([blob], fileName, {
          type: `image/${fileExtension}`,
          lastModified: timestamp,
        });

        file.preview = blobUrl;
        file.path = fileName;

        return onCropImage(file);
      },
      `image/${fileExtension}`,
      1
    );
  }, [onCropImage, selectedImage]);

  useEffect(() => {
    setCropState((prevState) => ({
      ...prevState,
      src: !selectedImage ? "" : selectedImage.preview,
    }));
  }, [selectedImage]);

  return (
    <>
      <div
        className={classnames("img-container position-relative mb-2", {
          "rounded-cropper": isRoundedCropper,
        })}
      >
        <Cropper
          style={{ height: 400, width: "100%" }}
          aspectRatio={cropState.ratio1 / cropState.ratio2}
          preview=".img-preview"
          src={cropState.src}
          ref={cropper}
          crossOrigin="anonymous"
          zoomTo={cropState.zoom}
          dragMode={cropState.dragMode}
          rotateTo={cropState.rotate}
          scaleX={cropState.scaleX}
          scaleY={cropState.scaleY}
          enable={cropState.enable}
          disable={cropState.disable}
          viewMode={cropState.viewMode}
          minCropBoxWidth={MIN_CROP_BOX_WIDTH}
          minCropBoxHeight={MIN_CROP_BOX_HEIGHT}
          imageSmoothingQuality="high"
          imageSmoothingEnabled={true}
          center
          guides
        />
        <div className="cropper-zoom d-flex flex-column position-absolute">
          <button
            className="cropper-zoom__button--round d-flex p-0 rounded-circle"
            onClick={() => cropper.current.cropper?.rotate(90)}
          >
            <i className="bx bx-rotate-right m-auto" />
          </button>
          <button
            className="cropper-zoom__button--round d-flex mt-2 p-0 rounded-circle"
            onClick={() => cropper.current.cropper?.rotate(-90)}
          >
            <i className="bx bx-rotate-left m-auto" />
          </button>
          <button
            className="cropper-zoom__button--round d-flex mt-2 p-0 rounded-circle"
            onClick={() => cropper.current.cropper?.zoom(0.1)}
          >
            <i className="bx bx-plus m-auto" />
          </button>
          <button
            className="cropper-zoom__button--round d-flex mt-2 p-0 rounded-circle"
            onClick={() => cropper.current.cropper?.zoom(-0.1)}
          >
            <i className="bx bx-minus m-auto" />
          </button>
        </div>
      </div>
      <div className="modal-buttons-wrapper">
        <CustomButton className="outline-primary" onClick={onCloseCropper}>
          Cancel
        </CustomButton>
        <CustomButton
          className={`filled-primary ${saveDisabled ? "disabled" : ""}`}
          onClick={cropImage}
        >
          Save
        </CustomButton>
      </div>
    </>
  );
};

ImageCropper.propTypes = {
  selectedImage: PropTypes.any,
  isRoundedCropper: PropTypes.bool,
  onCropImage: PropTypes.func,
  onCloseCropper: PropTypes.func,
};
