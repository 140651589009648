/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from "react";
import Bugsnag from "@bugsnag/js";

import Divider from "../../Divider";
import { Col, Row } from "reactstrap";
import FormikSingleSelect from "../../../../../base/components/FormikSingleSelect";
import React from "react";
import FormikGroupAddon from "../../GroupAddon";
import { useField } from "formik";
import FormikInput from "../../../../../base/components/FormikInput";
import { useService } from "../../../../../base/hooks/useService";
import ToasterService from "../../../../../services/ToastService";
import MercariExtension from "../../../../../services/MercariExtension";
import { MAX_LENGTH_ZIP } from "../../../../../base/constants/forms";
import { transformLbToOz } from "../../../helpers/transformLbtoOz";
import ToolTip from "../../../../../base/components/ToolTip/ToolTip";
import { StoredField } from "../../../../../base/components/StoredField";

export const SHIPPING_PAYER_BUYER = 1;
export const SHIPPING_PAYER_I_PAY = 2;

export const SHIP_ON_YOUR_OWN = 2;
export const PREPAID_LABEL = 1;

const shippingPayers = [
  {
    label: "The buyer",
    id: SHIPPING_PAYER_BUYER,
  },
  {
    label: "I’ll pay",
    id: SHIPPING_PAYER_I_PAY,
  },
];

const HOW_DO_YOU_WANT_SHIP_OPTIONS = [
  {
    label: "Prepaid label",
    id: PREPAID_LABEL,
  },
  {
    label: "Ship on your own",
    id: SHIP_ON_YOUR_OWN,
  },
];

const ShippingTemplate = ({
  shippings,
  title,
  categoryId,
  brandId,
  loading = false,
  description,
}) => {
  const [{ value: weightLbValue }] = useField({ name: "weightLb" });
  const [{ value: weightOzValue }] = useField({ name: "weightOz" });
  const [{ value: shippingPayer }] = useField({ name: "shippingPayer" });
  const [, , { setValue: setSuggestedShippingClassIds }] = useField({
    name: "suggestedShippingClassIds",
  });
  const howToShip = "howToShip";
  const [{ value: howToShipValue }] = useField({ name: howToShip });
  const [{ value: shippingId }, , { setValue: setShippingId }] = useField({
    name: "shippingId",
  });
  const [, , { setValue: setShippingFee }] = useField({ name: "shippingFee" });
  const [shippingParams, updateShipingParams] = useState(null);

  const isPayerBuyer = shippingPayer === SHIPPING_PAYER_BUYER;
  const showPackageDetails = PREPAID_LABEL === howToShipValue || isPayerBuyer;

  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);

  /**
   *
   * @type {MercariExtension}
   */
  const extension = useService(MercariExtension);

  const skipShipping = !title || !categoryId;

  const weightFilter = (weight, maxWeight, minWeight, retailRate) => {
    return weight <= maxWeight && weight >= minWeight && !!retailRate;
  };

  const defaultWeightFilter = (
    maxSuggestedWeightInOunce,
    minSuggestedWeightInOunce,
    maxWeight,
    minWeight,
    retailRate
  ) => {
    return (
      maxWeight === maxSuggestedWeightInOunce &&
      minWeight === minSuggestedWeightInOunce &&
      !!retailRate
    );
  };

  // filter list
  const filteredShipping = useMemo(() => {
    if (
      skipShipping ||
      !shippingParams ||
      !shippingParams?.selectableShippingClassIds
    )
      return [];

    const { selectableShippingClassIds } = shippingParams;
    const { maxSuggestedWeightInOunce, minSuggestedWeightInOunce } =
      selectableShippingClassIds;

    let filterFunction = ({ maxWeight, minWeight, retailRate }) => {
      return defaultWeightFilter(
        maxSuggestedWeightInOunce,
        minSuggestedWeightInOunce,
        maxWeight,
        minWeight,
        retailRate
      );
    };

    if (weightOzValue > 0) {
      filterFunction = ({ maxWeight, minWeight, retailRate }) => {
        return weightFilter(weightOzValue, maxWeight, minWeight, retailRate);
      };
    }

    if (weightLbValue > 0) {
      filterFunction = ({ maxWeight, minWeight, retailRate }) => {
        return weightFilter(
          transformLbToOz(weightLbValue) + (weightOzValue || 0),
          maxWeight,
          minWeight,
          retailRate
        );
      };
    }

    return shippings.filter(filterFunction);
  }, [shippings, skipShipping, shippingParams, weightLbValue, weightOzValue]);

  useEffect(() => {
    if (skipShipping) {
      return;
    }

    extension
      .getShippingParams({
        categoryId,
        name: title,
        brandId,
        description,
        shippingPayerId: parseInt(shippingPayer, 10),
      })
      .then((response) => {
        //Ensure the response is valid
        if (!response?.data?.sell) {
          console.error('Invalid Mercari shipping params response:', response);
          Bugsnag.notify(new Error('Invalid Mercari shipping params response'), (event) => {
            event.addMetadata('getShippingParamsError', {
              categoryId,
              title,
              brandId,
              description,
              shippingPayer,
            });
          });

          //Show error message to the user
          toastr.error("Something went wrong (error 1.05)");

          return;
        }

        const {
          data: { sell },
        } = response;

        updateShipingParams(sell);
      })
      .catch((e) => {
        Bugsnag.notify(e, (event) => {
          event.addMetadata("getShippingParamsError", {
            categoryId,
            title,
            brandId,
            description,
            shippingPayer,
          });
        });
      });
  }, [
    title,
    categoryId,
    extension,
    brandId,
    description,
    shippingPayer,
    skipShipping,
    toastr,
  ]);

  // cache ship price
  useEffect(() => {
    if (!shippingId || isPayerBuyer) {
      setShippingFee(0);
      return;
    }

    const ship = filteredShipping?.find(({ id }) => id === shippingId);

    setShippingFee(parseFloat(ship?.fee / 100).toFixed(2) || 0);
  }, [filteredShipping, shippingId, isPayerBuyer]);

  // Need for Mercari posting
  useEffect(() => {
    setSuggestedShippingClassIds([
      shippingParams?.selectableShippingClassIds
        ?.selectableShippingClassIds[0] || 0,
    ]);
  }, [shippingParams]);

  // Reset value - when (dropdown)element is hidden
  useEffect(() => {
    if (!showPackageDetails) setShippingId(null);
  }, [showPackageDetails]);

  return (
    <>
      <h3 className="bold-black mb-4">Shipping</h3>
      <Divider />
      <Row className="mb-4">
        <Col md="6">
          <StoredField fieldKey="shipsFrom" />
          <FormikInput
            name="shipsFrom"
            label="Ships from* "
            maxLength={MAX_LENGTH_ZIP}
            containerClassName="mb-3"
            placeholder="Enter 5-digit Zip code"
          />
        </Col>

        <Col md="6">
          <StoredField fieldKey="shippingPayer" options={shippingPayers} />
          <FormikSingleSelect
            loading={loading}
            name="shippingPayer"
            label="Who do you want to pay for shipping?* "
            placeholder="Choose "
            options={shippingPayers}
          />
        </Col>
      </Row>

      {SHIPPING_PAYER_I_PAY === shippingPayer && (
        <Row className="mb-4">
          <Col md="6">
            <FormikSingleSelect
              name={howToShip}
              label="How do you want to ship your item?* "
              placeholder="Choose "
              options={HOW_DO_YOU_WANT_SHIP_OPTIONS}
            />
          </Col>
        </Row>
      )}

      {showPackageDetails && (
        <>
          <Row className="mb-4">
            <Col md="3">
              <FormikGroupAddon
                label={"Package weight*"}
                name="weightLb"
                groupText="lb"
                placeholder={"0"}
              />
            </Col>
            <Col md="3">
              <FormikGroupAddon
                label={" "}
                name="weightOz"
                groupText="oz"
                placeholder={"0"}
              />
            </Col>
            <Col md="2">
              <FormikGroupAddon
                label="Package dimensions"
                name="depth"
                groupText="in"
                containerClassName={"package-details"}
                placeholder={"0"}
              />
            </Col>
            <Col md="2">
              <FormikGroupAddon
                label=" "
                name="width"
                groupText="in"
                placeholder={"0"}
              />
            </Col>
            <Col md="2">
              <FormikGroupAddon
                label=" "
                name="height"
                groupText="in"
                placeholder={"0"}
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md="6">
              <FormikSingleSelect
                name="shippingId"
                label="Shipping Label* "
                placeholder="Choose "
                options={filteredShipping}
                subText={
                  "Mercari requires you to get a tracking number in order for them to confidently release funds if buyer\n" +
                  "\t\t\t\t\t\t\tdoesn`t confirm delivery. Any return shipping fee will be withdrawn from your account."
                }
              />
            </Col>
            <Col md="6">
              <ToolTip
                classNames="info-icon"
                text="Shipping prices on Mercari may be different than what is displayed
            here"
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ShippingTemplate;
