import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import ImportProductService from "../../../services/ImportProductService";
import EbayService from "../../../services/EbayService";
import EtsyService from "../../../services/EtsyService";
import MercariService from "../../../services/MercariService";
import PoshmarkService from "../../../services/PoshmarkService";
import MarketPlaceService from "../../../services/MarketplaceService";
import QueuePromises from "../../../base/classes/QueuePromises";
import { ACTIVE_INVENTORY_LIMIT } from "../constants/importInventory";
import { changeImportCounters } from "../../../store/import/actions";
import { compareTwoDate } from "../../sold/helpers";
import { FORM_TYPES } from "../../drafts/const/form";
import { FORM_TYPES_ACCOUNT_CODES } from "../../drafts/pages/Settings/page/AutomationSetup/constants";
import { SOLD_INVENTORY_LABEL } from "../constants";
import { MARKETPLACES_NAME_LOWER } from "../../../pages/drafts/constants";
import { useError } from "../../../base/contexts/ErrorContext";

const INITIAL_STATE = { current: 0, total: 0 };

export const useImportProducts = (onFinally = () => {}) => {
  /**
   * @type {MarketPlaceService}
   */
  const marketplaceService = useService(MarketPlaceService);

  /**
   * @type {EtsyService}
   */
  const ebayService = useService(EbayService);

  /**
   * @type {EtsyService}
   */
  const etsyService = useService(EtsyService);

  /**
   * @type {MercariService}
   */
  const mercariService = useService(MercariService);

  /**
   * @type {PoshmarkService}
   */
  const poshmarkService = useService(PoshmarkService);

  const { setErrorData }= useError();

  const [countActiveImports, updateCountImports] = useState(0);
  const [countActivePreparing, updateCountPreparing] = useState(0);
  const [, { registerPromise, disable }] = useLoading();

  const [activePoshmarkImport, setActivePoshmarkImport] = useState(false);
  const [activeEtsyImport, setActiveEtsyImport] = useState(false);
  const [activeMercariImport, setActiveMercariImport] = useState(false);
  const [activeEbayImport, setActiveEbayImport] = useState(false);

  const dispatch = useDispatch();

  const updateCounts = useCallback((callback) => {
    dispatch(changeImportCounters((prev) => callback(prev)));
  }, []);

  /**
   * @type {ImportProductService}
   */
  const importProductService = useService(ImportProductService);

  const handleUpdateImportDateMethodsMap = useMemo(
    () => ({
      [FORM_TYPES.EBAY]: (date) => ebayService.updateLastSyncedDate(date),
      [FORM_TYPES.ETSY]: (date) => etsyService.updateLastSyncedDate(date),
      [FORM_TYPES.POSHMARK]: (date) =>
        poshmarkService.updateLastSyncedDate(date),
      [FORM_TYPES.MERCARI]: (date) => mercariService.updateLastSyncedDate(date),
    }),
    [mercariService, poshmarkService, ebayService, etsyService]
  );

  const handleUpdateImportSoldDate = (selectedProducts = []) => {
    let sortedProducts = [];

    if (selectedProducts?.[0]?.productOrder?.dateSold) {
      sortedProducts = selectedProducts.sort(
        (
          { productOrder: { dateSold: leftDateSold } },
          { productOrder: { dateSold: rightDateSold } }
        ) => {
          return moment(leftDateSold).diff(moment(rightDateSold));
        }
      );
    } else {
      sortedProducts = selectedProducts;
    }

    const latestOrderDate =
      sortedProducts[sortedProducts.length - 1]?.productOrder?.dateSold;

    return latestOrderDate;
  };

  const importing = useCallback(
    (productsToImport, marketplace, productsType) => {
      updateCountImports((prev) => prev + 1);

      //Mark the import as active
      if (marketplace === MARKETPLACES_NAME_LOWER.poshmark) {
        setActivePoshmarkImport(true);
      } else if (marketplace === MARKETPLACES_NAME_LOWER.etsy) {
        setActiveEtsyImport(true);
      } else if (marketplace === MARKETPLACES_NAME_LOWER.mercari) {
        setActiveMercariImport(true);
      } else if (marketplace === MARKETPLACES_NAME_LOWER.ebay) {
        setActiveEbayImport(true);
      }

      const queue = new QueuePromises();
      const fullInfoProducts = [];

      productsToImport.forEach((product) => {
        fullInfoProducts.push(product);
        const task = () =>
          importProductService.importProduct(product, marketplace).then(() => {
            updateCounts((prev) => ({
              ...prev,
              current: prev.current + 1,
            }));
          });

        queue.add(task, (error) => {
          if (error.error?.code === ACTIVE_INVENTORY_LIMIT) {
            throw error;
          }
        });
      });

      registerPromise(queue.getPromise());

      queue.finally(() => {
        onFinally();
        updateCountImports((prev) => prev - 1);

        if (countActiveImports === 0) {
          if (marketplace === MARKETPLACES_NAME_LOWER.poshmark) {
            setActivePoshmarkImport(false);
          } else if (marketplace === MARKETPLACES_NAME_LOWER.etsy) {
            setActiveEtsyImport(false);
          } else if (marketplace === MARKETPLACES_NAME_LOWER.mercari) {
            setActiveMercariImport(false);
          } else if (marketplace === MARKETPLACES_NAME_LOWER.ebay) {
            setActiveEbayImport(false);
          }

          //Clear error data in case we warned of a duplicate import
          setErrorData('');
        }

        if (productsType !== SOLD_INVENTORY_LABEL) return;

        try {
          const newImportSoldDate =
            handleUpdateImportSoldDate(fullInfoProducts);

          marketplaceService.getMarketplace().then(({ data }) => {
            const isNewDateNewestAsLastSyncDate = compareTwoDate(
              data[FORM_TYPES_ACCOUNT_CODES[marketplace]].lastSyncedAt,
              newImportSoldDate
            );

            if (
              !data[FORM_TYPES_ACCOUNT_CODES[marketplace]].lastSyncedAt &&
              !newImportSoldDate
            )
              return;

            // if (
            //   isNewDateNewestAsLastSyncDate &&
            //   data[FORM_TYPES_ACCOUNT_CODES[marketplace]].lastSyncedAt
            // ) {
            //   handleUpdateImportDateMethodsMap[marketplace](
            //     data[FORM_TYPES_ACCOUNT_CODES[marketplace]].lastSyncedAt
            //   );
            // } else {
            //   handleUpdateImportDateMethodsMap[marketplace](newImportSoldDate);
            // }
          });
        } catch (e) {
          Bugsnag.notify(e, (event) => {
            event.addMetadata("importingError", {
              products: fullInfoProducts,
            });
          });
        }
      });
    },
    [
      onFinally,
      updateCounts,
      updateCountImports,
      registerPromise,
      handleUpdateImportDateMethodsMap,
      importProductService,
      marketplaceService,
    ]
  );

  const resetCounts = useCallback(() => {
    updateCounts(() => INITIAL_STATE);
  }, [updateCounts]);

  const startPreparing = useCallback(() => {
    updateCountPreparing((prev) => prev + 1);
  }, []);

  const endPreparing = useCallback(() => {
    updateCountPreparing((prev) => prev - 1);
  }, []);

  return {
    isLoading: !!countActiveImports || !!countActivePreparing,
    disable,
    importing,
    resetCounts,
    startPreparing,
    endPreparing,
    activePoshmarkImport,
    activeEtsyImport,
    activeMercariImport,
    activeEbayImport,
  };
};
