/* eslint-disable react/prop-types */
import { useField } from "formik";
import { useEffect, useMemo, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../../base/hooks/useService";
import ToasterService from "../../../../../services/ToastService";
import MercariExtension from "../../../../../services/MercariExtension";
import React from "react";
import styles from "./CalcFees.module.scss";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import ErrorMessageDefault from "../../../../../base/components/ErrorMessageDefault";

const Fee = ({ message, fee, bold }) => {
  return (
    <div
      key={message}
      className={joinClassNames(styles.fee, bold && styles.bold)}
    >
      <p>{message}</p>
      <p>
        {bold ? "" : "-"}${Math.abs(fee).toFixed(2)}
      </p>
    </div>
  );
};

export const CalcFees = ({ from, shippingPayer, zipCode, floorPrice }) => {
  const [{ value }] = useField({ name: from });
  const [{ value: shippingPayerId }] = useField({ name: shippingPayer });
  const [fees, updateFees] = useState([]);
  const [{ value: shipping }] = useField({ name: "shippingFee" });
  const [, , { setValue: setSalesFee }] = useField({ name: "salesFee" });
  const [{ value: shippingId }] = useField({ name: "shippingId" });

  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);

  /**
   * @type {MercariExtension}
   */
  const extension = useService(MercariExtension);

  useEffect(() => {
    extension
      .calcFees(value, shippingPayerId, zipCode, floorPrice, shippingId)
      .then((response) => {
        //Ensure the response is valid
        if (!response?.data?.smartSalesFee) {
          console.error('No smartSalesFee in response from Mercari calcFees:', response);

          Bugsnag.notify(new Error('No smartSalesFee in response from Mercari calcFees'), (event) => {
            event.addMetadata('calcFeesError', {
              response,
            });
          });

          //Show error message to the user
          toastr.error("Something went wrong (error 1.03)");

          return;
        }
        
        const {
          data: {
            smartSalesFee: { fees },
          },
        } = response;

        setSalesFee(
          fees.reduce((sum, fee) => {
            return sum + fee.calculatedFee;
          }, 0)
        );

        updateFees(fees);
      })
      .catch((e) =>
        Bugsnag.notify(e, (event) => {
          event.addMetadata("MercariCalcFeesError", {
            from,
            shippingPayer,
            zipCode,
            floorPrice,
            value,
            shippingId,
          });
        })
      );
  }, [extension, value, shippingPayerId, zipCode, floorPrice, shippingId, toastr]);

  const allFees = useMemo(() => {
    let list = [...fees];

    if (!list.length) return [];

    if (shipping) {
      list.push({
        calculatedFee: shipping,
        message: "Shipping",
      });
    }

    const total = list.reduce((sum, fee) => {
      return sum - fee.calculatedFee;
    }, value);

    list.push({
      calculatedFee: total,
      bold: true,
      message: "You earn",
    });

    return list;
  }, [fees, shipping, value]);

  if (!value) return null;

  return (
    <>
      {allFees.map(({ message, calculatedFee, bold }) => {
        return (
          <Fee
            key={message}
            message={message}
            fee={calculatedFee}
            bold={bold}
          />
        );
      })}

      <div className="is-invalid-input is-invalid" />
      {!!allFees.length && allFees[allFees.length - 1]?.calculatedFee < 0 && (
        <ErrorMessageDefault>
          Price is too low to make a profit.
        </ErrorMessageDefault>
      )}
    </>
  );
};
