/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";

import ProductStatus from "../ProductStatus";
import CustomButton from "../../../../base/components/CustomButton";
import { SETTINGS_LINKS } from "../../pages/Settings/config";
import DropdownItem from "../DropdownItem";

import "./index.scss";
import { ReactComponent as ArrowDown } from "./images/icon-input-shevron-dark-grey.svg";
import { useProductStatus } from "../../hooks/useProductStatus";
import {
  MARKETPLACES_NAMES,
  PRODUCT_STATUSES,
  PRODUCT_STATUS_METHODS,
  SOLD_FIELDS,
} from "../../constants";
import {
  EBAY_NAME,
  INVENTORY_TABS,
  STARTINTG_PRICE_NAME,
} from "../DraftEbayFormInner/partials/ListingUrl/constants";
import { INVENTORY_STATUSES } from "../../../inventory/pages/EditInventory/constants";
import { PRODUCT_STATUS_STYLES } from "../ProductStatus/constants";
import { get } from "lodash";
import { getCountOfActiveItems } from "../../helpers/getTableFieldValue";
import { useTotalProducts } from "../../hooks/useProduct";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import { useProfile } from "../../../profile/hooks/useProfile";
import { getAvailabilityCreatingListing } from "../../../inventory/helpers/getAvailableCreatingListing";
import Permissions from "../../../../base/helpers/Permission";
import { phrases } from "../../../../store/phrases";
import { CustomBanner } from "../../../../base/components/CustomBanner";

const DraftHeader = ({
  title,
  status,
  showSettings,
  marketplace,
  fetchedData,
  toggleStatus,
  children,
  onHandleDelete,
  formValues,
  marketplaceData = null,
  marketplaceAccountKey = null,
  isConnected = true,
}) => {
  const [isOpenStatusDropdown, setIsOpenStatusDropdown] = useState(false);
  const history = useHistory();

  const ONE_ACTIVE_LISTING = 1;
  const [{ total }] = useTotalProducts(PRODUCT_TYPE.DRAFT);

  const countOfActiveListing = useMemo(() => {
    return getCountOfActiveItems(fetchedData);
  }, [fetchedData]);

  const goToSettings = () => {
    history.push(SETTINGS_LINKS.MARKETPLACE);
  };

  const { draftId } = useParams();

  const marketplaceObjectName = INVENTORY_TABS.find(
    (marketplaceType) => marketplaceType.type === marketplace
  );

  const getPriceSold = (marketplaceType) => {
    if (marketplaceType === EBAY_NAME) {
      return (
        get(
          fetchedData?.[marketplaceObjectName.objectName],
          SOLD_FIELDS.PRICE_SOLD[marketplace],
          ""
        ) ||
        get(
          fetchedData?.[marketplaceObjectName.objectName],
          STARTINTG_PRICE_NAME,
          ""
        )
      );
    } else {
      return get(
        fetchedData?.[marketplaceObjectName.objectName],
        SOLD_FIELDS.PRICE_SOLD[marketplace],
        ""
      );
    }
  };

  const [modal, onClick, processing] = useProductStatus({
    marketplace,
    id: draftId,
    countOfActiveListing,
    toggleStatus,
    soldValues: {
      marketplace: MARKETPLACES_NAMES[marketplace],
      dateListed: fetchedData?.[marketplaceObjectName.objectName]?.statusAt,
      brand:
        fetchedData?.brand?.name ||
        fetchedData?.[marketplaceObjectName.objectName]?.brandName,
      categoryName:
        fetchedData?.[marketplaceObjectName.objectName]?.categoryName,
      soldDate: new Date(),
      priceSold: getPriceSold(marketplace),
      costOfGood: get(
        fetchedData?.[marketplaceObjectName.objectName],
        SOLD_FIELDS.COST_OF_GOOD[marketplace],
        ""
      ),
      productId: draftId,
      title: get(
        fetchedData?.[marketplaceObjectName.objectName],
        SOLD_FIELDS.TITLE[marketplace],
        ""
      ),
      sku: get(
        fetchedData?.[marketplaceObjectName.objectName],
        SOLD_FIELDS.SKU[marketplace],
        ""
      ),
      color: get(
        fetchedData?.[marketplaceObjectName.objectName],
        SOLD_FIELDS.COLOR[marketplace],
        ""
      ),
      shippingCost:
        formValues?.shippingFee ||
        get(
          fetchedData?.[marketplaceObjectName.objectName],
          SOLD_FIELDS.SHIPPING_COST[marketplace],
          ""
        ),
      // "buyer": "string" //TODO need to add after approve
    },
    listingId:
      fetchedData?.[marketplaceObjectName.objectName]?.listingId || null,
    onHandleDelete,
    fetchedData,
    formValues,
  });

  const handleOpenStatusDropdown = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (status === PRODUCT_STATUSES[INVENTORY_STATUSES.notListed]) return;
      setIsOpenStatusDropdown((isOpenStatusDropdown) => !isOpenStatusDropdown);
    },
    [setIsOpenStatusDropdown, status]
  );

  const productStatusStyle =
    status === PRODUCT_STATUSES[INVENTORY_STATUSES.notListed]
      ? PRODUCT_STATUS_STYLES.notPosted
      : PRODUCT_STATUS_STYLES.isPosted;

  const [{ total: totalInventory }] = useTotalProducts(PRODUCT_TYPE.ACTIVE);

  const isUnmergeActiveByLimit = useMemo(() => {
    return getAvailabilityCreatingListing(totalInventory);
  }, [totalInventory]);

  const [{ activeSubscription: { type } = {} }] = useProfile();

  const isDelistBtnDisabled = Permissions.disableCreateDraft(type, total);

  const isUnmergeInactive = useMemo(() => {
    return (
      countOfActiveListing <= ONE_ACTIVE_LISTING ||
      !isUnmergeActiveByLimit[type]
    );
  }, [countOfActiveListing, isUnmergeActiveByLimit, type]);

  return (
    <>
      <div className="d-flex justify-content-between mb-4 draft-form-header">
        <div className="d-flex align-items-center">
          <p className="md-title-grey mb-0 text-uppercase d-inline-flex">
            {title}
          </p>
          {status && (
            <Dropdown
              isOpen={isOpenStatusDropdown}
              toggle={handleOpenStatusDropdown}
              className="d-inline-block"
            >
              <DropdownToggle
                className="btn header-item waves-effect"
                tag="button"
              >
                <ProductStatus statusStyle={productStatusStyle}>
                  {status}
                  {productStatusStyle !== PRODUCT_STATUS_STYLES.notPosted && (
                    <ArrowDown className="arrow-down-svg" />
                  )}
                </ProductStatus>
              </DropdownToggle>
              {marketplaceData?.data?.[marketplaceAccountKey] &&
                isConnected && (
                  <DropdownMenu>
                    <DropdownItem
                      title="Relist"
                      onClick={(event) => {
                        handleOpenStatusDropdown(event);
                        onClick(PRODUCT_STATUS_METHODS.relist);
                      }}
                    />
                    <DropdownItem
                      title="Mark as sold"
                      onClick={(event) => {
                        handleOpenStatusDropdown(event);
                        onClick(PRODUCT_STATUS_METHODS.sold);
                      }}
                    />
                    <DropdownItem
                      title="Delist"
                      onClick={(event) => {
                        if (isDelistBtnDisabled) return;
                        handleOpenStatusDropdown(event);
                        onClick(PRODUCT_STATUS_METHODS.delist);
                      }}
                      disabled={isDelistBtnDisabled}
                    />
                    <DropdownItem
                      title="Unmerge"
                      onClick={(event) => {
                        if (isUnmergeInactive) return;
                        handleOpenStatusDropdown(event);
                        onClick(PRODUCT_STATUS_METHODS.unmerge);
                      }}
                      disabled={isUnmergeInactive || processing}
                    />
                  </DropdownMenu>
                )}
            </Dropdown>
          )}
        </div>
        <div className="d-flex align-items-center">
          {children}
          {showSettings && (
            <CustomButton
              className="outline-primary-sm ml-2"
              onClick={goToSettings}
            >
              Settings
            </CustomButton>
          )}
        </div>
      </div>
      {modal}
    </>
  );
};

export default DraftHeader;
