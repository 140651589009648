import Bugsnag from "@bugsnag/js";
import QueuePromises from "../../../base/classes/QueuePromises";
import PoshmarkExtension from "../../../services/PoshmarkExtension";
import { toNumber } from "../../sold/helpers";
import { PoshmarkBaseProduct } from "./PoshmarkBaseProduct";
import { PoshmarkSoldBundle } from "./PoshmarkBundle";
import { PoshmarkHelpers } from "./PoshmarkHelpers";

const SOLD_STATUS = "sold_out";
const ACTIVE_STATUS = "available";

export class PoshmarkActiveProduct extends PoshmarkBaseProduct {
  static checkIsValid(product) {
    const productsToFilter = {
      [SOLD_STATUS]: true,
      [ACTIVE_STATUS]: true,
    };

    return !!productsToFilter[product?.inventory.status];
  }

  /*

  *
  * isAddToBoutique
  * categoryId
  *
  * discountedShipping
  * sku
  * costPrice
  * otherInfo
  * availability
  * sizeId
  * quantityAvailable
  * quantitySold
  * sizeSku
  *
  * productAdditionalFields
  *
  * transactionFees
  * */
  constructor({
    id,
    brand_id: brandId,
    brand: brandName,
    first_published_at: firstListedAt,
    inventory,
    original_price: originalPrice,
    title,
    price,
    buyer,
    picture_url,
    description,
    pictures,
    category_v2,
    colors,
    sale_id,
    style_tags,
    size_obj,
    inventory_booked_at,
    total_price_amount,
    seller_shipping_discount_id: discountedShipping,
    category_features = [],
    catalog: { department_obj, category_obj },
    seller_private_info,
    $sort,
    condition,
  }) {
    super();
    const { status } = inventory;
    const isSold = status === SOLD_STATUS;
    const { id: categoryId } = category_v2;

    const labels = [
      department_obj?.display,
      category_obj?.display,
      category_features[0]?.display,
    ].filter(Boolean);

    let primaryColor, colorName, secondaryColor;

    const [primary, secondary] = colors;

    if (primary) {
      primaryColor = primary.rgb;
      colorName = primary.name;
    }

    if (secondary) {
      secondaryColor = secondary.rgb;
    }

    const imageUrls = [{ url: picture_url }]
      .concat(pictures)
      .map(({ url }) => url);

    this.sale_id = sale_id;
    this.isImported = false;
    this.$sort = $sort;

    this.data = {
      title,
      isSold,
      ...(brandName && {
        brandName,
        brandId: brandName,
      }),
      description,
      imageUrls,
      primaryColor,
      colorName,
      discountedShipping,
      listingPrice: price,
      listingId: id,
      ...PoshmarkBaseProduct.parseCondition(condition),
      categoryId: category_features[0]?.id || categoryId,
      tags: style_tags || [],
      sizeName: size_obj.display_with_system_and_set || "",
      sizeId: `0,${size_obj.size_system},${size_obj.id}`,
      categoryName: labels.join(" / "),
      categoryShortName: labels[labels.length - 1],
      ...(secondaryColor && {
        secondaryColor,
      }),
      ...(firstListedAt && {
        firstListedAt: PoshmarkBaseProduct.convertDate(firstListedAt),
      }),
      ...(isSold && {
        buyer: buyer?.username || buyer?.full_name || "",
        dateSold: PoshmarkBaseProduct.convertDate(
          inventory_booked_at || inventory?.status_changed_at
        ),
        priceSold: toNumber(total_price_amount?.val),
      }),
      ...(seller_private_info && {
        sku: seller_private_info?.sku,
        costPrice:
          toNumber(seller_private_info?.cost_price_amount?.val) < 0
            ? 0
            : toNumber(seller_private_info?.cost_price_amount?.val),
        otherInfo: seller_private_info?.other_info,
      }),
    };

    if (originalPrice) {
      this.data.originalPrice = originalPrice;
    }

    this.listingId = this.data.listingId;
  }

  loadAdditionalFields() {
    if (!this.sale_id) return Promise.resolve();

    const poshmark = new PoshmarkExtension();

    return poshmark
      .getSaleDetails(this.sale_id)
      .then((resp) => {
        const {
          result: {
            order_details: { order },
          },
        } = resp;
        const { seller_shipping_discount_amount, pm_fee_amount } = order;

        this.data.marketplaceFees = pm_fee_amount?.val;

        if (seller_shipping_discount_amount?.val) {
          this.data.shippingExpenses = seller_shipping_discount_amount?.val;
        }
      })
      .catch((e) => Bugsnag.notify(e));
  }
}

export class PoshmarkSoldProduct extends PoshmarkBaseProduct {
  static checkIsValid() {
    return true;
  }

  constructor({
    id,
    productId,
    title,
    total_price_amount,
    picture_url,
    is_bundle,
    bundle_size,
  }) {
    super();
    const imageUrls = [picture_url];

    this.isImported = false;

    this.data = {
      title,
      imageUrls,
      listingPrice: toNumber(total_price_amount.val),
      listingId: productId,
    };

    this.listingId = this.data.listingId;
    this.sale_id = id;
    this.isBundle = !!is_bundle;
    this.size = bundle_size || 1;
  }

  getOrderFields(order) {
    return PoshmarkHelpers.getOrderFields(order);
  }

  loadAdditionalFields() {
    if (!this.sale_id) return Promise.resolve();

    const poshmark = new PoshmarkExtension();
    const queue = new QueuePromises();
    const drafts = [];

    if (this.isBundle) {
      return poshmark
        .getSaleDetails(this.sale_id)
        .then((response) => {
          const {
            result: {
              order_details: { order },
            },
          } = response;

          order.line_items.forEach(({ product_id }) => {
            const loadData = () =>
              poshmark.getDraft(product_id).then((data) => drafts.push(data));
            queue.add(loadData);
          });

          return new Promise((resolve) => {
            queue.finally(() => resolve(drafts));
          }).then((products) => {
            return new PoshmarkSoldBundle(order, products).getProducts();
          });
        })
        .then((products) => {
          this.data = products;
        })
        .catch((e) => Bugsnag.notify(e));
    }

    return Promise.all([
      poshmark.getDraft(this.data.listingId),
      poshmark.getSaleDetails(this.sale_id),
    ])
      .then(([draftData, soldData]) => {
        const {
          result: {
            order_details: { order },
          },
        } = soldData;

        this.fullData = draftData;

        return (this.data = {
          ...this.data,
          ...this.getDraftFields(draftData),
          ...this.getOrderFields(order),
        });
      })
      .catch((e) => {
        Bugsnag.notify(e);

        return null;
      });
  }
}
