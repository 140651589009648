import Http from "./Http";
import {
  convertSpecFieldEbayForView,
  convertSpecFieldForView,
} from "../pages/drafts/hooks/useSaveSubmit";
import EbayService from "./EbayService";
import { parseFromHtml } from "../base/helpers/parseFromHtml";
import { convertToHtml } from "../base/helpers/convertToHtml";
import { filterDomesticServices } from "../pages/drafts/hooks/useFormatEbayDomesticShippingServices";
import Bugsnag from "@bugsnag/js";

class DraftsService extends Http {
  static $displayName = "DraftsService";

  getListDrafts(params) {
    return this.get("/products", { params });
  }

  copyDraft(id) {
    return this.post(`/products/${id}/clone`);
  }

  deleteDraft(id) {
    return this.delete(`/products/${id}`);
  }

  createDraft(values) {
    if (values?.productEbaySpecificFields?.description) {
      values.productEbaySpecificFields.description = convertToHtml(
        values?.productEbaySpecificFields?.description
      );
    }
    return this.post("/products", values);
  }

  getDraft(id) {
    return this.get(`/products/${id}`).then(async (data) => {
      const {
        data: {
          productEtsySpecificFields,
          productEbaySpecificFields,
          productMercariSpecificFields,
          productPoshmarkSpecificFields,
          description,
          category,
        },
      } = data;
      const basic = data.data;

      const promises = [];

      if (category) {
        basic.categoryId = category.id;
      }
      if (description) {
        basic.description = parseFromHtml(description);
      }

      if (productEbaySpecificFields?.description) {
        productEbaySpecificFields.description = parseFromHtml(
          productEbaySpecificFields?.description
        );
      }

      if (productEtsySpecificFields?.productAdditionalFields) {
        productEtsySpecificFields.productAdditionalFields =
          convertSpecFieldForView(
            productEtsySpecificFields.productAdditionalFields
          );
      }

      if (productEbaySpecificFields?.productAdditionalFields) {
        productEbaySpecificFields.productAdditionalFields =
          convertSpecFieldEbayForView(
            productEbaySpecificFields.productAdditionalFields
          );
      }

      if (productMercariSpecificFields?.tags) {
        const tags = (productMercariSpecificFields.tags =
          productMercariSpecificFields.tags.map(({ name }) => name));
        productMercariSpecificFields.tags_1 = tags[0];
        productMercariSpecificFields.tags_2 = tags[1];
        productMercariSpecificFields.tags_3 = tags[2];
      }

      if (productPoshmarkSpecificFields?.tags) {
        productPoshmarkSpecificFields.tags =
          productPoshmarkSpecificFields.tags.map(({ tagId }) => tagId.trim());
      }

      data.data.files = data.data.files.map((dataFile, index) => {
        promises.push(
          window
            .fetch(dataFile.link, {
              cache: "no-store", // no-store
            })
            .then((file) => file.blob())
            .then((blob) => {
              data.data.files[index].file = blob;
            })
            .catch((e) =>
              Bugsnag.notify(e, (event) => {
                event.addMetadata("getDraftError", {
                  dataFile,
                });
              })
            )
        );

        return {
          preview: dataFile.link,
          ...dataFile,
        };
      });

      await Promise.all(promises);

      return data;
    });
  }

  async updateDraft(id, values) {
    if (values?.productEbaySpecificFields?.description) {
      values.productEbaySpecificFields.description = convertToHtml(
        values?.productEbaySpecificFields?.description
      );
    }

    if (values?.productEbaySpecificFields?.shippingService) {
      if (
        parseInt(values?.productEbaySpecificFields?.shippingType, 10) === 1 ||
        parseInt(values?.productEbaySpecificFields?.shippingType, 10) === 2
      ) {
        try {
          const services = await new EbayService().getShippingData();
          const domesticServices = filterDomesticServices(services);

          const service = domesticServices.find(
            (service) =>
              service.shippingServiceId ===
              values?.productEbaySpecificFields?.shippingService
          );

          if (service) {
            const { shippingService } = service;

            values = {
              ...values,
              productEbaySpecificFields: {
                ...values.productEbaySpecificFields,
                shippingService,
              },
            };
          }
        } catch (e) {
          if (e?.error?.message !== "EBAY_ACCOUNT_NOT_FOUND") {
            Bugsnag.notify(e, (event) => {
              event.addMetadata("shippingServiceError", {
                values,
              });
            });
          }
        }
      }
    }

    if (
      values?.productMercariSpecificFields?.tags &&
      typeof values?.productMercariSpecificFields?.tags === "string"
    ) {
      const {
        productMercariSpecificFields: { tags },
      } = values;

      const formattedTags = tags.split(",").map((tag) => tag.trim());

      values = {
        ...values,
        productMercariSpecificFields: {
          ...values.productMercariSpecificFields,
          tags: formattedTags,
        },
      };
    }

    return this.put(`/products/${id}`, values);
  }

  getProductsEbay(params) {
    return this.get(`/products/ebay/active`, { params });
  }

  getSoldProductsEbay(params) {
    return this.get(`/products/ebay/sold`, { params });
  }

  getProductEtsy(params) {
    return this.get(`/products/etsy/active`, { params });
  }

  mergeProducts(model) {
    return this.post("/products/merge", model);
  }
}

export default DraftsService;
