export const phrases = {
  postingToEbay:
    "Posting to eBay. You may navigate away while we process your listing. We'll let you know when the process is complete.",
  process: "Please wait while we process your request",
  preparingData: "Preparing item data",
  connectingToPoshmark: "Connecting to Poshmark",
  preparingImages: "Preparing images",
  postingToPoshmark: "Posting to Poshmark",
  savingItemInformation: "Saving item information",
  postingToMercari: "Posting to Mercari",
  updatingOnPoshmark: "Updating item on Poshmark",
  updatingOnMercari: "Updating item on Mercari",
  processingImage: "Processing image",
  updateConfirmationDescription:
    "Updating will identify and log your sales, delist them from other marketplaces, as well as update your Analytics/Bookkeeping. Proceed?",
  updateConfirmationTitle: "Update",
  poshmarkNotConnectedDrafts:
    "We had trouble accessing your Poshmark account. Please be sure you are logged into poshmark.com in a different tab. Once you are logged in, click “Connect” so we can try again.",
  ebayNotConnectedDrafts:
    "We had trouble accessing your Ebay account. Please be sure you are logged into ebay.com in a different tab. Once you are logged in, click “Connect” so we can try again.",
  mercariNotConnectedDrafts:
    "We had trouble accessing your Mercari account. Please be sure you are logged into mercari.com in a different tab. Once you are logged in, click “Connect” so we can try again.",
  mercariReauthentication:
    "Refresh this page to re-establish the connection to Mercari. If this banner doesn't go away, you may need to reconnect your Mercari account.",
  mercariIssueDetected:
    "We have detected issues with Mercari and we're actively working on resolving it.",
  updatingYourSales: "Updating your sales",
  fetchingActiveInventory: "Fetching active inventory",
  updateComplete: "Sales have been updated",
  fetchingSoldItems: "Fetching sold items from connected marketplaces",
  toggleMergeVisibility: "Toggle displaying merged items",
  pageWasClosed: "Page was closed!",
  acceptOffersAtLeast: "Accept offers of at least",
  declineOfferLowerThan: "Decline offer lower than",
  uploadImages: "Upload images",
  urlUpdateWarning:
    "To ensure that all functions operate correctly, double-check the URL before updating.",
  totalMarketplaceFeesLabel: "Marketplace fees + additional fees",
  priceRefunded: "Price refunded",
  priceRefundedTooltip: "Enter the sales price refunded to buyer here",
  shippingCost: "Shipping cost",
  shippingRefundCost: "Shipping refund cost",
  shippingCostRefundTooltip:
    "If you are receiving a reimbursement for shipping costs incurred, e.g. Poshmark reimbursing shipping discount, enter that amount here",
  returnShippingCost: "Return shipping cost",
  returnShippingCostTooltip:
    "If you are incurring a return shipping cost, e.g. you are paying for return shipping, enter that amount here",
  miscRefund: "Shipping/Miscellaneous Refund to Buyer",
  miscRefundTooltip:
    "If you are incurring a shipping or miscellaneous cost, e.g. you are refunding a shipping cost charged to buyer, enter that amount here",
  shippingChargedToBuyer: "Shipping charged to buyer",
  shippingChargedToBuyerSoldTooltip:
    "Shipping charged to buyer that you received as revenue e.g. eBay flat shipping",
  comingSoon: "Coming soon!",
  connectionsRefresh:
    "When connecting a marketplace, after you log in and the pop-up closes, please refresh the page if the connection status does not automatically update.",
  ebayDisabledError:
    "Condition may not show any options, due to a limitation by eBay. We are actively working with eBay to remove the limitation.",
};
